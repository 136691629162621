.addpost_wrapper{
  padding: 10px;

  .user_img_wraper{
    width: 50px;
    height: 50px;
    border-radius: 50%;

    overflow: hidden;
    border: 2px solid #145388;
    float: left;
    margin: 0 3px;

    img{ width: 100%; }
  }

  .content_wrap{
    display: flex;

    .content_txt{ //textarea
      border-radius: 40px;
      width: 100%;
      font-size: 18px;
      color: #444;
      padding: 10px 15px;
    }

    .content_btn_link{
      padding: 0 5px;
      .text_link{
        color: #696c6f;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 0;
        cursor: pointer;
      }
      .text_type{
        color: #777;
      }

      .text_link:hover{
        text-decoration: underline;
      }
    }
  }

  .add_gallery_wrap{
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;

    .added_img{
      width: 25%;
      display: inline-block;
      overflow: hidden;
      height: 140px;

      i{
        position: absolute;
        font-size: 18px;
        color: #666;
        margin: 5px;
        cursor: pointer;
      }
      img{
        width: 100%;
      }
    }
  }

  .actions{
    .action{
      font-size: 25px;
      cursor: pointer;
      margin: 0 3px;
      color: #444;
    }
    .active{
      color: #145388;
      font-size: 28px;
    }
  }
}

.mdl_post{

  .header{
    background-color: #145388;
    padding-right: 16px;
    padding-left: 24px;
    color: #fff;
    display: flex;
    height: 50px;
    flex-shrink: 0;

    .name{
      flex: 1;
      margin-top: 10px;
      font-size: 27px;
    }
    .right-action{
      padding-top: 8px;
      margin-top: 2px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      text-align: center;
    }
    .right-action:hover{
      background-color: hsla(0,0%,100%,.15);
    }
  }

  .body{
    padding: 12px 24px;

    .specifications_wrap{
      display: flex;

      .right-side{
        flex: 1;
        padding: 0 5px;
      }

      .limitations_wrap{
        display: flex;
        
        .limitation_types{
          background-color: transparent;
          color: rgba(0,0,0,.6);
          box-shadow: inset 0 0 0 1px rgba(0,0,0,.6);
          cursor: pointer;
          padding: 3px;

          .type_text span{
            padding: 0 5px;
            color: rgba(0,0,0,.6);
            font-weight: 600;
          }
        }
      }
    }

    .user_img_wraper{
      width: 50px;
      height: 50px;
      border-radius: 50%;
  
      overflow: hidden;
      border: 2px solid #145388;
      // float: left;
      // margin: 0 3px;
  
      img{ width: 100%; }
    }


    .content_text{
      border: none;
      width: 100%;
      margin: 15px 0;
      font-size: 17px;
    }



    



    .add_gallery_wrap{
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;
  
      .added_img{
        width: 25%;
        display: inline-block;
        overflow: hidden;
        height: 140px;
  
        i{
          position: absolute;
          font-size: 18px;
          color: #666;
          margin: 5px;
          cursor: pointer;
        }
        img{
          width: 100%;
        }
      }
    }
  }


  .checks_wrap{
    // padding: 12px 24px;
    
    .info{
      padding: 12px 24px;
      color: rgba(0,0,0,.6);
      margin: 0
    }

    .check_item{
      display: flex !important;
      padding: 12px 24px !important;
      border-top: 1px solid #ddd;
      cursor: pointer;

      .name{
        flex: 1 !important;
        font-size: 17px;
        margin: 0;
      }
      // .subname{

      // }
    }

    .check_item:hover{
      background-color: #f3f6f8;
    }
  }

  .footer{
    border-top: 1px solid #d7d7d7;
    padding: 10px 28px;
    text-align: right;

    .actions{
      flex: 1;
      text-align: left;

      .action{
        font-size: 25px;
        cursor: pointer;
        margin: 0 3px;
        color: #444;
      }
      .active{
        color: #a3498b;
        font-size: 28px;
      }
    }
  }
}

.iframe_video_wrap{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 10px;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.add-event-cover{
  margin: 0 !important;
  overflow: hidden;
  .caption{
    display: none;
    // label, i{ display: hidden; }
  }
}
.add-event-cover:hover{
  .caption{
    display: block;
    position: absolute;
    bottom: 0;
    background: #0005;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    cursor: pointer;

    label{
      margin: 0; cursor: pointer;
    }
  }
}


.card_action{
  padding: 5px;
  padding-top: 30px;
  font-size: 20px;
  color: #F44336;

  i{ cursor: pointer; }
}

.ev-card{
  overflow: hidden;

  .img-wrapper{
    width: 100%;
    max-height: 105px;
    overflow: hidden;

    img{ width: 100%; }
  }

  .details{
    padding: 5px;

    .date{
      color: #F44336;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 5px;
    }
    .name{
      color: #444;
      font-weight: 500;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
//whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'

.preview_details{
  padding: 10px;
  .name{
    width: 100%;
  }
  .date{
    color: #F44336;
    font-size: 16px;
    i{
      color: #303030
    }
  }

  .address{
    font-size: 16px;
  }
}

.course-card{
  padding: 10px;
  .avatar{
    width: 100%;
    border-radius: 0.75rem;
  }

  .name{
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 15px;
  }
  .date{
    color: #999;
  }

  .owner{
    margin: 0;
  }
  .icon{
    margin: 0;
    i{
      color: "#999"
    }
  }
}


.select_full_width{
  width: 100% !important;
}

.session_wrap{
  padding: 0 5px;
  border-left: 2px solid #a3498b;
  margin-bottom: 15px;

  .trash_right{
    float: right;
    border: 1px solid #bbb;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    padding-top: 5px;
    background: #fff;
    color: #e7523e;
    margin-top: 4px;
    cursor: pointer;
    /* right: -10px; */
    /* top: -5px; */
    z-index: 999;
    /* position: absolute; */
    margin-right: -25px;
  }
}

.chapter-wraper{
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 10px;
  min-height: 40px;
  margin-top: 30px;

  .chapter-head{
    margin-top: -30px;
    margin-bottom: 50px;

    .arrow-toggle{
      float: left;
      border: 1px solid #bbb;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      padding-top: 7px;
      background: #fff;
      color: #444;
      margin-top: 4px;
      cursor: pointer;
    }
  
    .chapter-name{
      width: 25%;
      float: left;
      padding: 8px;
      border-radius: 5px;
      margin: 0 5px;
    }
  
    .chapter-trash{
      float: right;
      border: 1px solid #bbb;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      padding-top: 5px;
      background: #fff;
      color: #e7523e;
      margin-top: 4px;
      cursor: pointer;
    }
  }

  .chapter-body{
    padding: 5px 0;
  }

  .chapter-footer{
    text-align: right;
  }
}

.trash_inst{
  float: right;
  margin: 5px;
  margin-top: -30px;
  cursor: pointer;
  color: rgb(244, 67, 54);
  font-size: 15px;
}









.add_img_wrap {
  overflow: hidden;
  border-radius: 15px !important;
  border: 1px solid #ddd; 
}
.add_img_wrap img { width: 100%; }
.add_img_wrap .caption {
  display: none;
  position: absolute;
  bottom: 0;
  background: #0005;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 25px;
  cursor: pointer; 
}
.add_img_wrap .caption label {
  margin: 0;
  cursor: pointer; 
}

.add_img_wrap:hover .caption {
  display: block; 
}












.iframe_rocket{
  position: absolute;
  width: 100%;
  height: 100%;

  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:112.1%;
    margin-top: 60px;

  }
}



.comments-wrapper{
  max-height: 200px !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
  }
 
}
